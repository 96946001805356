<template>
  <div class="container d-flex justify-content-center flex-column">
    <div class="view-image-center">
      <img
        src="https://ik.imagekit.io/powerbiz/img/admin-saas/Store.png?updatedAt=1635392034840"
      />
      <div class="mt-3 pbz-font subtitle-md-medium" style="color: #1a1a1a">
Belum Ada Toko atau Saluran Penjualan
      </div>

      <div class="mt-3 pbz-font body-md-regular" style="color: #999999">
Saat ini akun anda belum memiliki toko atau saluran penjualan. <br />
Silakan tambahkan toko dan integrasikan dengan sistem kami.
      </div>

      <div class="d-flex justify-content-center mt-5">
        <!-- Button add channel -->
      <div class="button-set-store" @click.prevent="() => ($emit('setModalContent', { key: 'listChannel', value: true  }), $router.push({ path: 'dashboard', query: { is_add_channel: 1, select_channel: 1 }}))">
      <div
        class="integration__button pbz-font body-md-bold"
        style="width: 278px; height: 48px">
        {{$t('integration.addChannel')}}
      </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'empty-saleschannel',
}
</script>

<style lang="scss">
.button-add-product {
  background: #0559cb !important;
  border: 1px solid #0559cb !important;
  color: #ffffff !important;
  height: 48px !important;
  width: 401px !important;
}
.button-tambah-produk {
  background: #0559cb !important;
  border: 1px solid #0559cb !important;
  color: #ffffff !important;
  height: 40px !important;
  width: 120% !important;
  font-size: 14px;
  margin-left: 8px;
}
.button-add-product:hover {
  background: #04449a !important;
}
.view-image-center {
  margin-top: 10%;
}
</style>
