<template>
  <a-modal
    :visible="visible1"
    :footer="null"
    :closable="true"
    class="modal__setupchannel1"
    @cancel="handleCancel"
  >
    <template>
      <ConfirmSku
        v-if="modalContent.ConfirmSku"
        :product-to-add-sku="productToAddSku"
        @setModalContentSuccessSaveSku="setModalContentSuccessSaveSku"
        @handleCancel="handleCancel"
      />
    </template>

    <template>
      <SuccessSaveSku
        v-if="modalContent.SuccessSaveSku"
        @handleCancel="handleCancel"
      />
    </template>
  </a-modal>
</template>

<script>
import ConfirmSku from '@/components/Integration/ConfirmProduct/ConfirmSku'
import SuccessSaveSku from '@/components/Integration/ConfirmProduct/SuccessSaveSku'

export default {
  components: {
    ConfirmSku,
    SuccessSaveSku,
  },
  props: ['visible1', 'productToAddSku'],
  data: function () {
    return {
      modalContent: {
        ConfirmSku: true,
        SuccessSaveSku: false,
        AddSku: false,
      },
    }
  },

  mounted () {

  },

  methods: {
    setModalContentConfirmSku() {
      this.modalContent.waitingConfirmation = false
      this.modalContent.ConfirmSku = true
    },

    setModalContentSuccessSaveSku() {
      this.modalContent.ConfirmSku = false
      this.modalContent.SuccessSaveSku = true
    },

    handleCancel() {
      this.visible1 = false
    },

  },
}
</script>

<style lang="scss">
.modal__setupchannel1 {
  .ant-modal {
    width: 720px !important;
    &-content {
      width: fit-content;
      padding-top: 1rem !important;
      border-radius: 10px !important;
      background: #ffffff !important;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    }

    .sku {
      position: relative;
      // width: 100px !important;
    }

    .button-set-store {
      width: 174px;
      height: 52px;
      color: #ffffff;
      background: #202759;
      border: 1px solid #202759;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 11px;
    }

    .button-set-store:hover {
      background: #323d8c !important;
      border: 1px solid #323d8c !important;
    }

    .button-to-dashboard:hover {
      color: #323d8c !important;
      text-decoration: underline !important;
      text-decoration-color: #323d8c !important;
      cursor: pointer !important;
    }
  }
}
</style>
