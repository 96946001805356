<template>
  <div class="row" style="height: 370px; width: 670px">
    <div class="col-12 d-flex justify-content-between p-2 mb-3">
      <div
        class="pbz-font subtitle-md-medium pl-2"
        style="font-size: 24px !important; color: #1a1a1a"
      >
         {{$t('integration.confirmProduct')}}
      </div>

      <div
        class="pbz-font subtitle-md-medium"
        style="font-size: 24px !important; color: #1a1a1a"
      >

<!-- halaman produk yang sedang di confirm dari keseluruhan jumlah total produk -->
        {{dataActiveConfirmSku+ 1 + '/' + productToAddSku.length}}
      </div>
    </div>
    <div class="w-100 px-3" v-for="(data, index) in productToAddSku" :key="index">
      <div class="card-product col-12" v-if="dataActiveConfirmSku == index">
        <div class="row d-flex">
          <div class="col-3">
            <img
              class="my-auto"
              :src="data.image_url"
              height="110px"
              width="110px"
              style="
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
                border-radius: 30px;
                padding: 10px;
              "
            />
          </div>
          <div class="col-9 pbz-font subtitle-sm-medium card-block pt-3"
          >
            <h5>
              <p class="pl-3">{{ data.title }}</p>
            </h5>

            <!-- button pilih sku -->
            <div class="" v-if="isShowAddSKU">
              <AddSku @setModalContentSaveSku="setModalContentSaveSku"
              @handleCancel="handleCancel" />
            </div>

            <div class="pl-3">
              <div v-if="!skuProductSelected">
                <a-button
                  @click.prevent="showAddSKU()"
                  class="button-set-sku"
                  block
                  type="primary" ghost
                  value="large"
                >
                  <a-icon type="link" /><span class="text-set-sku text-primary">Konfirmasi SKU</span>
                </a-button>
              </div>

              <div v-if="skuProductSelected">
                <a-button
                  @click.prevent="showAddSKU()"
                  class="button-set-sku"
                  block
                  type="primary" ghost
                  value="large"
                >
                  <a-icon class="mr-2" type="link" />
                  <span class="text-set-sku text-primary">{{ skuProductSelected.sku }}</span>
                </a-button>
              </div>

              <div>
                <p
                  v-if="messageSuccess"
                  class="my-2 pbz-font caption-md-medium"
                  style="color: blue"
                >
                  Kode Berhasil Tersimpan
                </p>

                <p
                  v-if="messageFail"
                  class="my-2 pbz-font caption-md-medium"
                  style="color: red"
                >
                  Anda belum konfirmasi SKU dari produk ini.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button bawah -->
    <div class="col-12 mt-1 mb-4 d-flex justify-content-around align-items-center">
      <div class="button-to-dashboard mx-auto" @click="$emit('handleCancel')">
        Konfirmasi Nanti
      </div>

      <div
        v-if="buttonName == 'Simpan dan Lanjutkan'"
        class="button-set-store"
        type="submit"
        style="width: 50% !important"
        @click.prevent="langsungSimpan"
      >
        <div class="font-set-store ml-2 pbz-font body-md-bold">
          Simpan dan Lanjutkan
        </div>
      </div>

      <div
        v-if="buttonName == 'Simpan'"
        class="button-set-store"
        type="submit"
        style="width: 50% !important"
        @click.prevent="skuProductSaved"
      >
        <div class="font-set-store mx-1 pbz-font body-md-bold">Simpan</div>
      </div>

      <div
        v-if="buttonName == 'Selesai'"
        class="button-set-store"
        type="submit"
        style="width: 50% !important"
        @click.prevent="() => $emit('setModalContentSuccessSaveSku', true)"
      >
        <div class="font-set-store mx-1 pbz-font body-md-bold">Selesai</div>
      </div>
    </div>
  </div>
</template>

<script>
import AddSku from '../AddSku'

export default {
  name: 'confirm-sku',

  components: {
    AddSku,
  },

  data: function () {
    return {
      data: [],
      dataActiveConfirmSku: 0,
      sku: '',
      buttonName: 'Simpan dan Lanjutkan',
      isSkuProductSaved: false,
      skuProductSelected: '',
      isShowAddSKU: false,
      messageSuccess: false,
      messageFail: false,
    }
  },

  methods: {
    setModalContentSkuSaved(value) {
      this.skuProductSaved = value
    },
    setModalContentSaveSku(value) {
      this.skuProductSelected = value
      this.buttonName = 'Simpan'
      this.isShowAddSKU = false
    },

    toIntegrationPage() {
      this.$router.push({ path: '/integration' })
    },

    showAddSKU() {
      this.isShowAddSKU = !this.isShowAddSKU
      this.messageFail = false
    },

    skuProductSaved() {
      this.buttonName = 'Selesai'
      this.messageSuccess = true
    },

    langsungSimpan() {
      if (!this.skuProductSelected) {
        this.messageFail = true
      }
    },

    // addItem() {
    //   console.log('addItem')
    //   this.items.push(`New item ${index++}`)
    // },
  },

  computed: {
  },
  props: ['productToAddSku'],
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss">
div.ant-progress-circle,
div.ant-progress-line {
  margin-right: 8px;
  margin-bottom: 8px;
}

.card-product {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  // max-height: 130px;
}
.button-to-dashboard {
  color: #323d8c !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}
.button-to-dashboard:hover {
  color: #323d8c !important;
  text-decoration: underline !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.button-set-store {
  color: #04449A;
}

.button-set-store:hover {
  background: #04449A;
  text-decoration-color: aliceblue;
}

.button-set-sku:hover {
  background: #04449A;
  text-decoration-color: aliceblue;
}
.text-set-sku {
  font-size: 16px;
}
.text-set-sku:hover {
  font-size: 16px;
  color: #ffffff !important;
}

.font-set-store {
  font-size: 16px;
  color: #ffffff !important;
}

.font-set-store:hover {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: antiquewhite;
}
</style>
