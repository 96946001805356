<template>
  <div style="width: 100% !important">
    <template v-if="!channels.length">
      <EmptySaleschannel />
    </template>
    <template v-else>
      <!-- modal dari halaman integrasi -->
      <ModalConfirm
        :visible1="visible1"
        :product-to-add-sku="productToAddSku"
        @handleCancel="handleCancel"
      />
      <ModalEdit
        :visible2="visible2"
        :channel-clicked="channelClicked"
        @handleCancel="handleCancel"
      />
      <ModalDelete
        :visible3="visible3"
        :channel-clicked="channelClicked"
        @handleCancel="handleCancel"
      />

      <div class="d-flex justify-content-between align-items-center">
        <!-- Title Bar -->
        <div class="pbz-font subtitle-sm-medium" style="color: #1a1a1a">
          Integrasi
        </div>

        <!-- search bar-->
        <div class="d-flex align-items-center">
          <div class="integration__search-bar">
            <input
              type="text"
              placeholder="Cari Toko atau Kanal Penjualan"
              @input="handleSearchBar"
            />
            <icon-search class="icon-form" />
          </div>
        </div>

        <!-- Button add channel -->
        <div class="button-set-store" @click.prevent="() => ($emit('setModalContent', { key: 'listChannel', value: true }), $router.push({ path: 'dashboard', query: { is_add_channel: 1, select_channel: 1 }}))">
          <div
            class="integration__button pbz-font body-md-bold"
            style="width: 178px; height: 48px"
          >
            Tambah Toko
          </div>
        </div>
      </div>

      <!-- Integration List -->
      <div
        class="
          integration__list
          head
          mt-3
          pbz-font
          caption-md-bold
          row
          align-items-center
          bg-light
        "
      >
        <div class="col-4 d-flex justify-content-start" style="width: 15%">
          Nama Toko
        </div>
        <div class="col-2 d-flex justify-content-start">
          Status Toko
        </div>
        <div class="col-2 d-flex justify-content-start">
          Waktu Otorisasi
        </div>
        <div class="col-1 d-flex justify-content-start">
          Negara
        </div>
        <div class="col-1 d-flex justify-content-start">
          Catatan
        </div>
        <div class="col-2 d-flex justify-content-center">
          Pilihan
        </div>
      </div>

      <div
        v-for="(channel, index) in channels"
        :key="index"
        class="
          mt-2
          integration__list
          item
          row
          align-items-left
          pbz-font
          body-sm-medium
          py-2
        "
      >
        <div class="col-4 d-flex justify-content-start align-items-center">
          <div class="d-flex flex-column align-items-center mr-2" style="width: 10%;">
            <img :src="channel.sales_channel.logo_url" width="90%" height="90%" />
          </div>
          <div
            class="mx-1"
            style="
              color: #1A1A1A,
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ channel.title }}
          </div>
        </div>

        <div class="col-2 d-flex justify-content-start align-items-center">
          <div class="" style="color: #1a1a1a">
            {{ channel.status }}
          </div>
        </div>

        <div class="col-2 d-flex justify-content-start align-items-center">
          <div class="" style="color: #1a1a1a">
            {{ channel.connected_at }}
          </div>
        </div>

        <div class="col-1 d-flex justify-content-start align-items-center">
          <div class="" style="color: #1a1a1a">
            {{ channel.sales_channel.country }}
          </div>
        </div>

        <div class="col-1 d-flex justify-content-start align-items-center">
          <div class="" style="color: #1a1a1a">
            {{ channel.notes }}
          </div>
        </div>
        <!-- icon dan tooltip -->

        <div class="col-2 d-flex justify-content-start align-items-center">
          <div class="mx-3" @click.prevent="handleClickConfirmSku(channel)">
            <a-tooltip placement="bottom" style="cursor: pointer">
              <template slot="title">
                <span> {{ $t('integration.confirmProduct') }}</span>
              </template>
              <span class="">
                <a-icon
                  type="exclamation-circle"
                  theme="twoTone"
                  two-tone-color="#D00000"
                  style="font-size: 24px"
                  @click.prevent="handleClickConfirmSku(channel)"
                />
              </span>
            </a-tooltip>
          </div>

          <div class="mx-3" @click.prevent="handleClickEdit(channel)">
            <a-tooltip placement="bottom" style="cursor: pointer">
              <template slot="title">
                <span class="bg-transparent">  {{ $t('integration.editChannel') }} </span>
              </template>
              <span class="">
                <pencil-icon />
              </span>
            </a-tooltip>
          </div>
          <div class="mx-3" @click.prevent="handleClickDelete(channel)">
            <a-tooltip placement="bottom" style="cursor: pointer">
              <template slot="title">
                <span>  {{ $t('integration.deleteChannel') }} </span>
              </template>
              <span class="">
                <disconnect-icon />
              </span>
            </a-tooltip>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div class="mt-5 d-flex flex-row-reverse align-items-center">
        <Pagination
          :total="Number(total_row)"
          :page-size="Number(limit)"
          :total-length-per-page="channels.length"
          :default-page="Number(page)"
          @changePage="changePage"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { getProductConfirm } from '@/api/channels/index'
import ModalConfirm from '@/components/Integration/ModalIntegrationPage/Confirm'
import ModalEdit from '@/components/Integration/ModalIntegrationPage/Edit/Modal.vue'
import ModalDelete from '@/components/Integration/ModalIntegrationPage/Delete/Modal.vue'

import IconSearch from '@/components/Icons/Search.vue'
import Pagination from '@/components/Pagination'
import PencilIcon from '@/components/Icons/Pencil.vue'
import DisconnectIcon from '@/components/Icons/Disconnect.vue'
import EmptySaleschannel from '@/views/integration'

const normalizePage = (value) => {
  if (isNaN(value)) {
    return 1
  }

  const page = Number(value)

  return page < 1 ? 1 : page
}

export default {
  name: 'IntegrationList',
  components: {
    ModalConfirm,
    ModalEdit,
    ModalDelete,
    IconSearch,
    Pagination,
    PencilIcon,
    DisconnectIcon,
    EmptySaleschannel,
  },
  data: function () {
    return {
      total_row: 0,
      timeout: 0,
      visible1: false,
      visible2: false,
      visible3: false,
      channelClicked: '',
      productToAddSku: [],
    }
  },
  computed: {
    page() {
      return normalizePage(this.$route.query.page)
    },
    limit() {
      return Number(this.$route.query.limit) || 15
    },
    channels() {
      const { channels } = this.$store.state.channel
      return channels
    },
  },
  watch: {
    $route() {
      this.fetchChannels()
    },
  },
  mounted() {
    this.fetchChannels()
  },
  methods: {
    async handleClickConfirmSku(channel) {
      await getProductConfirm({
        channel_code: channel.sales_channel.code,
        channel_id: channel.id,
        params: { status: 'UNCONFIRMED' },
      })
      .then(({ data: { data: response } }) => {
        this.productToAddSku = response
        this.visible2 = false
        this.visible3 = false
        this.visible1 = true
      })
    },
    handleClickEdit(channel) {
      this.channelClicked = channel
      this.visible1 = false
      this.visible2 = true
      this.visible3 = false
    },
    handleClickDelete(channel) {
      this.channelClicked = channel
      this.visible1 = false
      this.visible2 = false
      this.visible3 = true
    },

    handleCancel() {
      this.visible1 = false
      this.visible2 = false
      this.visible3 = false
    },

    fetchChannels() {
      this.$store
        .dispatch('channel/GETCHANNEL', {
          ...this.$route.query,
          page: this.page,
          limit: this.limit,
        })
        .then(({ total_row }) => {
          this.total_row = total_row
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSearchBar(e) {
      const value = e.target.value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({
          query: { ...this.$route.query, q: value, page: 1, limit: 10 },
        })
      }, 3000)
    },
    changePage(value) {
      this.$router.push({
        query: { ...this.$route.query, page: value, limit: this.limit },
      })
    },
  },
}
</script>

<style lang="scss">
.integration {
  &__search-bar {
    position: relative;
    width: 534px;
    height: 44px;

    input[type='text'] {
      border: 1px solid #999999;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 8px;
      box-sizing: border-box;
      transition: 0.3s;
    }

    input[type='text']:focus {
      border-color: rgb(5, 89, 203);
      box-shadow: 0 0 8px 0 rgb(5, 89, 203);
      color: #999999;
    }

    input[type='text'] {
      padding-left: 40px;
    }

    input::placeholder {
      color: #999999 !important;
      font-family: 'Poppins', sans-serif !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }

    .icon-form {
      position: absolute;
      left: 7px;
      top: 10px;
      color: #999999;
      transition: 0.3s;
    }
  }

  &__button-filter {
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 8px;
    width: 98px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
  }

  &__button {
    background-color: #0559cb !important;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__list {
    width: 100% !important;
    border-radius: 5px !important;
    border: 1px solid #cccccc !important;
    margin-left: 0px !important;

    &.head {
      color: #999999;
      height: 38px !important;
    }

    &.item {
      color: #1a1a1a;
      // height: 60px !important;
    }
  }
}
</style>
