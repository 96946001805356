<template>
    <div class="row align-items-center">
      <div class="col-12 my-auto mx-auto text-center justify-content-center align-items-center">
        <div>
        <img
          src="https://ik.imagekit.io/powerbiz/img/admin-saas/Success.png?updatedAt=1630892947577"
          width="240"
          height="240"
        />
      </div>

      <div class="col-12 d-flex flex-column">
        <div class="pbz-font subtitle-md-medium" style="color: #1A1A1A; font-size: 24px !important">
          Berhasil Tersambung
        </div>

        <div class="pbz-font body-sm-regular my-4" style="color: #999999 !important">
          Toko Anda telah berhasil tersambung dengan PowerBiz.
        </div>

      <div class="col-12 text-center">

            <div class="button-tambah-toko w-100 mx-1 pbz-font body-sm-bold my-3"
            @click.prevent="() => $emit('setModalContentListChannel', true)">
            Tambahkan Toko Lain
            </div>
      <div class="pbz-font body-md-bold button-to-dashboard mb-3" style="color: #202759" @click="closeModal()">
            Selesai
            </div>
    </div>
</div>
</div>
</div>
</template>

<script>

export default {
  name: 'success-save-sku',
  components: {},

  data() {
    return {

    }
  },
  computed: {
  },

  methods: {
    closeModal() {
      this.destroyAll()
    },

  },

}
</script>
<style scoope lang="scss">

    .button-tambah-toko {
      width: 90%;
      height: 52px;
      color: #FFFFFF;
      background: #202759;
      border: 1px solid #202759;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 11px;
    }

.button-tambah-toko:hover {
    background: #04449A !important
}

.font-set-store {
    font-size: 16px;
    color: #ffffff !important
}

.font-set-store:hover {
  font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: antiquewhite;
}

</style>
