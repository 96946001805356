<template>
  <div class="row" style="height: 440px; width: 484px">
    <p class="pbz-font subtitle-md-medium mx-3">
      Ubah Informasi Toko
    </p>
    <div
      class="col-12 text-center"
      style="color: #1a1a1a"
    >
      <div
        v-if="channelClicked.sales_channel.code == 'tokopedia_id'"
        class="col-12"
      >
        <img
          class="mx-auto my-5"
          src="@/assets/integration/channel/tokopedia.png"
        />
      </div>
      <div
        v-if="channelClicked.sales_channel.code == 'shopee_id'"
        class="col-12"
      >
        <img
          class="mx-auto my-5"
          src="@/assets/integration/channel/Shopee.png"
        />
      </div>
      <div v-if="channelClicked.sales_channel.code == 'lazada_id'" class="col-12">
        <img class="mx-auto my-5" src="@/assets/integration/channel/lazada.png" />
      </div>
      <div v-if="channelClicked.sales_channel.code == 'blibli'" class="col-12">
        <img class="mx-auto my-5" src="@/assets/integration/channel/blibli.png" />
      </div>
      <div v-if="channelClicked.sales_channel.code == 'bukalapak'" class="col-12">
        <img class="mx-auto my-5" src="@/assets/integration/channel/Bukalapak.png" />
      </div>
      <div v-if="channelClicked.sales_channel.code == 'zalora'" class="col-12">
        <img class="mx-auto my-5" src="@/assets/integration/channel/Zalora.png" />
      </div>
    </div>
    <div class="col-12 text-center">
      <a-form :form="form">
        <a-form-item
          :class="{
            [$style.container__form]: true,
          }"
        >
          <a-input
            v-model="title"
            :class="{
              [$style.container__form__input]: true,
            }"
          />
          <label
            :class="{
              [$style.container__form__label]: true,
            }"
            class="pbz-font overline-normal"
          >
            <span> Nama Toko </span>
          </label>
        </a-form-item>
      </a-form>
    </div>
    <div class="col-12 text-center">
      <div
        class="button-tambah-toko w-100 mx-1 pbz-font body-sm-bold my-3"
        htmlType="submit"
        @click="handleSubmit()"
      >
        Simpan
      </div>
      <div
        class="pbz-font body-md-bold button-to-dashboard mb-3"
        style="color: #202759"
        @click="$emit('handleCancel')"
      >
        Batal
      </div>
    </div>
  </div>
</template>

<script>
// import { crudChannel } from '@/api/business'

export default {
  name: 'VerifikasiChannel',
  props: ['channelClicked'],
  data() {
    return {
      title: this.channelClicked.title,
    }
  },
  computed: {
    editTitle() {
      return this.$store.state.editTitle
    },
  },
  created() {
    // const titleId = this.$route.params.id
    // this.$store.dispatch('getTitleId', titleId)
  },
  methods: {
    async handleSubmit() {
      const dataPost = {
        sales_channel: this.channelClicked.sales_channel,
        title: this.title,
        status: this.channelClicked.status,
        notes: this.channelClicked.notes,
      }
      // console.log('dataPost:kkkkkkkkkkkk ', dataPost)
      // await crudChannel({
      //   id: '',
      //   data: {
      //     ...values,
      //     sales_channel: {
      //       id: channelId,
      //       code: channelCode,
      //     },
      //   },
      // })
      // this.$store.dispatch('channel/EDITCHANNEL', dataPost)
      // this.$emit('setModalContentSuccessEdit', { value: true })
    },

    toDashboardPage() {
      this.$router.push({ path: '/dashboard' })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss">
div.ant-progress-circle,
div.ant-progress-line {
  margin-right: 8px;
  margin-bottom: 8px;
}

.loading {
  background-image: 'https://uploads.toptal.io/blog/image/122385/toptal-blog-image-1489082610696-459e0ba886e0ae4841753d626ff6ae0f.gif';
  z-index: 5;
}
.button-to-dashboard {
  color: #323d8c !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}
.button-to-dashboard:hover {
  color: #323d8c !important;
  text-decoration: underline !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.button-set-store {
  color: #0559cb;
}

.button-set-store:hover {
  background: #04449a;
}

.font-set-store {
  font-size: 16px;
  color: #ffffff !important;
}

.font-set-store:hover {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: antiquewhite;
}
</style>
