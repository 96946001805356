<template>
  <div class="row" style="height:480px; width:400px;">
    <div class="col-12 my-auto mx-auto text-center justify-content-center align-items-center">
      <div>
        <img
          src="@/assets/integration/channel/Disconnect.png"
          width="240"
          height="240"
        />
      </div>

      <div class="col-12 d-flex flex-column">
        <div class="pbz-font subtitle-md-medium" style="color: #1A1A1A; font-size: 24px !important">
          Putuskan sambungan
        </div>

        <div class="pbz-font body-sm-regular my-4" style="color: #999999 !important">
          Toko {{ channelClicked.title }} akan dihapus.
          <br />
          Apakah Anda yakin?
        </div>

        <div class="col-12 text-center">
          <div
            class="button-tambah-toko w-100 mx-1 pbz-font body-sm-bold my-3"
            @click="handleDelete()"
          >
            Ya, Hapus Toko
          </div>
          <div class="pbz-font body-md-bold button-to-dashboard mb-3" style="color: #202759" @click="$emit('handleCancel') ">
            Batal
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { crudChannel } from '@/api/business'

export default {
  name: 'SuccessSaveSku',
  components: {},
  props: ['channelClicked'],

  data() {
    return {

    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },

  methods: {
    async handleDelete() {
      await crudChannel({ id: this.channelClicked.sales_channel.status })
      .then(() => {
        this.$emit('handleCancel')
      })
      .catch((err) => {
        this.$notification.error({
          message: 'error',
          description: err.response?.data?.message || err.message,
        })
      })
    },
  },
}
</script>
<style scoope lang="scss">

    .button-tambah-toko {
      width: 90%;
      height: 52px;
      color: #FFFFFF;
      background: #202759;
      border: 1px solid #202759;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 11px;
    }

.button-tambah-toko:hover {
    background: #04449A !important
}

.font-set-store {
    font-size: 16px;
    color: #ffffff !important
}

.font-set-store:hover {
  font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: antiquewhite;
}

</style>
