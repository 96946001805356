<template>
  <a-modal
    :visible="visible2"
    :footer="null"
    :closable="true"
    class="modal__setupchannel2"
    @cancel="handleCancel"
  >
    <template>
      <Edit
        v-if="modalContent.Edit"
        :channel-clicked="channelClicked"
        @handleCancel="handleCancel"
      />
    </template>
    <!-- {{channelClicked}} -->
    <template>
      <SuccessEdit
        v-if="modalContent.SuccessEdit"
        @handleCancel="handleCancel"
      />
    </template>
  </a-modal>
</template>

<script>
import Edit from '@/components/Integration/ModalIntegrationPage/Edit/index.vue'
import SuccessEdit from '@/components/Integration/ModalIntegrationPage/Edit/SuccessEdit.vue'

export default {
  components: {
    Edit,
    SuccessEdit,
  },
  props: ['visible2', 'channelClicked'],
  data: function () {
    return {
      modalContent: {
        Edit: true,
        SuccessEdit: false,
      },
    }
  },

  mounted () {

  },

  methods: {
    setModalContentEdit() {
      this.modalContent.Edit = true
    },
    setModalContentSuccessEdit() {
      this.modalContent.Edit = false
      this.modalContent.SuccessEdit = true
    },

    handleCancel() {
      this.visible2 = false
    },

  },
}
</script>

<style lang="scss">
.modal__setupchannel2 {
  .ant-modal {
    width: 484px !important;
    &-content {
      width: fit-content;
      padding-top: 1rem !important;
      border-radius: 10px !important;
      background: #ffffff !important;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    }

    .sku {
      position: relative;
      // width: 100px !important;
    }

    .button-set-store {
      width: 174px;
      height: 52px;
      color: #ffffff;
      background: #202759;
      border: 1px solid #202759;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 11px;
    }

    .button-set-store:hover {
      background: #323d8c !important;
      border: 1px solid #323d8c !important;
    }

    .button-to-dashboard:hover {
      color: #323d8c !important;
      text-decoration: underline !important;
      text-decoration-color: #323d8c !important;
      cursor: pointer !important;
    }
  }
}
</style>
