<template>
  <div class="row">
    <div
      class="col-12 position-absolute bg-white card pb-1 p-0"
      style="z-index: 10"
    >
      <p class="text-center py-2 border-bottom">
        Konfirmasi SKU
      </p>
      <div
        class="kontainer-produk"
        style="width: 100%; height: 280px; overflow: scroll"
      >
        <div
          v-for="(product, index) in products"
          :key="index"
          class="
            add-sku
            col-12
            d-flex
            justify-content-between
            border-bottom
            p-0
            m-0
          "
          style="cursor: pointer"
          @click.prevent="() => $emit('setModalContentSaveSku', product)"
        >
          <!-- barang dagangan dari list master produk -->
          <div class="col-12 p-0 m-0 d-flex justify-content-between">
            <div class="col-3 py-1" style="border-radius: 30px">
              <img
                class="card p-2 mx-auto my-auto text-center"
                :src="product.image_url"
                height="85px"
                width="85px"
                style="border-radius: 20px"
              />
            </div>

            <div class="col-7 pbz-font body-md-medium card-block m-2">
              <h5>
                <p class="pt-2">
                  {{ product.sku }}
                </p>
              </h5>
              <span class="mr-2"> {{ product.title }} </span>
            </div>
          </div>
        </div>
      </div>
      <!-- button -->
      <div class="col-12 mt-4 mb-2 pb-3">
        {{ sku }}
        <div id="spoiler" style="display: none">
          <a-form :form="form">
            <a-form-item
              :class="{
                [$style.container__form]: true,
              }"
            >
              <a-input
                v-model="sku"
                :class="{
                  [$style.container__form__input]: true,
                }"
              />
              <label
                :class="{
                  [$style.container__form__label]: true,
                }"
                class="pbz-font overline-normal"
              >
                <span> SKU </span>
              </label>
            </a-form-item>
          </a-form>
        </div>

        <div v-if="!sku">
          <a-button
            class="button-set-sku"
            block
            type="primary"
            onclick="if(document.getElementById('spoiler') .style.display=='none') {document.getElementById('spoiler') .style.display=''}else{document.getElementById('spoiler') .style.display='none'}"
          >
            <a-icon class="mr-2" type="plus" /> Tambah SKU Baru
          </a-button>
        </div>

        <div v-else>
          <a-button
            block
            class="button-set"
            type="primary"
            html-type="submit"
            @click="handleSubmit()"
          >
            Simpan SKU Baru
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductCatalog } from '@/api/product'
import { addNewSku } from '@/api/channels/index'

export default {
  name: 'AddSku',
  data() {
    return {
      form: this.$form.createForm(this),
      sku: '',
      products: [],
    }
  },
  computed: {},

  watch: {
    $route() {
      this.fetchProducts()
    },
  },

  mounted() {
    this.fetchProducts()
  },
  methods: {
    check() {},
    handleChange() {},
    toDashboardPage() {
      this.$router.push({ path: '/dashboard' })
    },

    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await addNewSku({
            channel_code: values.channelCode,
            channel_id: values.channelId,
            data: {
              product_id: values.productId,
              catalog_id: values.catalogId,
              sku: values.sku,
            },
          })
          .then(() => {
            this.$emit('setModalSaveSku', true)
          })
          .catch((err) => {
            this.$notification.error({
              message: 'SKU harus di-isi dengan benar',
              description: err.response?.data?.message || err.message,
            })
          })
        }
      })
    },

    async fetchProducts() {
      await getProductCatalog({
        type: 'catalog',
        params: {
          ...this.$route.query,
          page: this.page,
          limit: this.limit,
        },
      })
        .then(({ data: { data: response } }) => {
          this.products = response
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss">
.add-sku {
  background-color: white;
  width: 490px;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.add-sku :hover {
  background-color: #f2f2f2;
}

.button-to-dashboard {
  color: #323d8c !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}
.button-to-dashboard:hover {
  color: #323d8c !important;
  text-decoration: underline !important;
  text-decoration-color: #323d8c !important;
  cursor: pointer !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.button-set-sku {
  // width: 174px;
  height: 52px;
  color: #ffffff;
  background: #202759;
  border: 1px solid #202759;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 11px;
}

.button-set-store:hover {
  background: #04449a;
}

.font-set-store {
  font-size: 16px;
  color: #ffffff !important;
}

.font-set-store:hover {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: antiquewhite;
}
</style>
