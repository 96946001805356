var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 position-absolute bg-white card pb-1 p-0",staticStyle:{"z-index":"10"}},[_c('p',{staticClass:"text-center py-2 border-bottom"},[_vm._v(" Konfirmasi SKU ")]),_c('div',{staticClass:"kontainer-produk",staticStyle:{"width":"100%","height":"280px","overflow":"scroll"}},_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"add-sku col-12 d-flex justify-content-between border-bottom p-0 m-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return (() => _vm.$emit('setModalContentSaveSku', product)).apply(null, arguments)}}},[_c('div',{staticClass:"col-12 p-0 m-0 d-flex justify-content-between"},[_c('div',{staticClass:"col-3 py-1",staticStyle:{"border-radius":"30px"}},[_c('img',{staticClass:"card p-2 mx-auto my-auto text-center",staticStyle:{"border-radius":"20px"},attrs:{"src":product.image_url,"height":"85px","width":"85px"}})]),_c('div',{staticClass:"col-7 pbz-font body-md-medium card-block m-2"},[_c('h5',[_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(product.sku)+" ")])]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(product.title)+" ")])])])])}),0),_c('div',{staticClass:"col-12 mt-4 mb-2 pb-3"},[_vm._v(" "+_vm._s(_vm.sku)+" "),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"spoiler"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{class:{
              [_vm.$style.container__form]: true,
            }},[_c('a-input',{class:{
                [_vm.$style.container__form__input]: true,
              },model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
                [_vm.$style.container__form__label]: true,
              }},[_c('span',[_vm._v(" SKU ")])])],1)],1)],1),(!_vm.sku)?_c('div',[_c('a-button',{staticClass:"button-set-sku",attrs:{"block":"","type":"primary","onclick":"if(document.getElementById('spoiler') .style.display=='none') {document.getElementById('spoiler') .style.display=''}else{document.getElementById('spoiler') .style.display='none'}"}},[_c('a-icon',{staticClass:"mr-2",attrs:{"type":"plus"}}),_vm._v(" Tambah SKU Baru ")],1)],1):_c('div',[_c('a-button',{staticClass:"button-set",attrs:{"block":"","type":"primary","html-type":"submit"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" Simpan SKU Baru ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }