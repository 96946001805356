<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M9.80169 7.9392C10.0974 6.69704 15.4801 1.31435 16.7223 1.0186C17.9644 0.722845 21.2769 4.03527 20.9811 5.27743C20.6854 6.51959 15.3027 11.9023 14.0605 12.198C13.7379 12.2748 13.2757 12.1083 12.7734 11.7979C13.1739 11.3844 13.5505 10.98 13.8658 10.6221C13.8963 10.6174 13.9257 10.6104 13.954 10.601C14.8412 10.3052 18.9817 6.16468 19.2775 5.27743C19.5732 4.39017 17.503 2.31991 16.6157 2.61566C15.7285 2.91141 11.5879 7.05194 11.2922 7.9392C11.2623 8.02898 11.2566 8.13087 11.2714 8.2412C10.942 8.53523 10.5782 8.87548 10.2071 9.23491C9.89332 8.72923 9.72445 8.26361 9.80169 7.9392Z" fill="#0559CB"/>
    <path d="M7.9389 9.80199C8.26289 9.72484 8.72772 9.89317 9.23263 10.2062C8.83476 10.6142 8.4566 11.0167 8.13372 11.3782C8.02363 11.3634 7.92195 11.3692 7.83234 11.399C6.94508 11.6948 2.80456 15.8353 2.5088 16.7226C2.21305 17.6098 4.28332 19.6801 5.17057 19.3844C6.05783 19.0886 10.1984 14.9481 10.4941 14.0608C10.5034 14.0329 10.5104 14.0038 10.5151 13.9736C10.9044 13.6303 11.3482 13.215 11.7988 12.7756C12.1084 13.2773 12.2744 13.7387 12.1977 14.0608C11.902 15.303 6.51929 20.6857 5.27713 20.9814C4.03497 21.2772 0.72255 17.9647 1.0183 16.7226C1.31406 15.4804 6.69674 10.0977 7.9389 9.80199Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2529 12.2871C16.2529 11.9419 16.5328 11.6621 16.8779 11.6621H20.6279C20.9731 11.6621 21.2529 11.9419 21.2529 12.2871C21.2529 12.6323 20.9731 12.9121 20.6279 12.9121H16.8779C16.5328 12.9121 16.2529 12.6323 16.2529 12.2871Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3245 14.9643C15.5463 14.6999 15.9406 14.6654 16.205 14.8873L19.0776 17.2977C19.3421 17.5196 19.3766 17.9138 19.1547 18.1782C18.9328 18.4427 18.5386 18.4772 18.2742 18.2553L15.4015 15.8448C15.1371 15.6229 15.1026 15.2287 15.3245 14.9643Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9732 21.3572C12.628 21.3572 12.3482 21.0774 12.3482 20.7322V16.9822C12.3482 16.6371 12.628 16.3572 12.9732 16.3572C13.3184 16.3572 13.5982 16.6371 13.5982 16.9822V20.7322C13.5982 21.0774 13.3184 21.3572 12.9732 21.3572Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77051 9.24715C5.77051 9.59233 5.49069 9.87215 5.14551 9.87215H1.39551C1.05033 9.87215 0.770508 9.59233 0.770508 9.24715C0.770508 8.90197 1.05033 8.62215 1.39551 8.62215H5.14551C5.49069 8.62215 5.77051 8.90197 5.77051 9.24715Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.69899 6.56997C6.47711 6.83439 6.08289 6.86888 5.81847 6.64701L2.9458 4.23655C2.68138 4.01468 2.64689 3.62046 2.86877 3.35603C3.09064 3.09161 3.48486 3.05712 3.74929 3.279L6.62195 5.68945C6.88637 5.91133 6.92086 6.30555 6.69899 6.56997Z" fill="#0559CB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05022 0.177032C9.39539 0.177032 9.67522 0.456854 9.67522 0.802032V4.55203C9.67522 4.89721 9.39539 5.17703 9.05022 5.17703C8.70504 5.17703 8.42522 4.89721 8.42522 4.55203V0.802032C8.42522 0.456854 8.70504 0.177032 9.05022 0.177032Z" fill="#0559CB"/>
  </svg>
</template>

<script>
export default {
  name: 'Disconnect',
}
</script>
