var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"height":"440px","width":"484px"}},[_c('p',{staticClass:"pbz-font subtitle-md-medium mx-3"},[_vm._v(" Ubah Informasi Toko ")]),_c('div',{staticClass:"col-12 text-center",staticStyle:{"color":"#1a1a1a"}},[(_vm.channelClicked.sales_channel.code == 'tokopedia_id')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/tokopedia.png")}})]):_vm._e(),(_vm.channelClicked.sales_channel.code == 'shopee_id')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/Shopee.png")}})]):_vm._e(),(_vm.channelClicked.sales_channel.code == 'lazada_id')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/lazada.png")}})]):_vm._e(),(_vm.channelClicked.sales_channel.code == 'blibli')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/blibli.png")}})]):_vm._e(),(_vm.channelClicked.sales_channel.code == 'bukalapak')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/Bukalapak.png")}})]):_vm._e(),(_vm.channelClicked.sales_channel.code == 'zalora')?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"mx-auto my-5",attrs:{"src":require("@/assets/integration/channel/Zalora.png")}})]):_vm._e()]),_c('div',{staticClass:"col-12 text-center"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{class:{
          [_vm.$style.container__form]: true,
        }},[_c('a-input',{class:{
            [_vm.$style.container__form__input]: true,
          },model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
            [_vm.$style.container__form__label]: true,
          }},[_c('span',[_vm._v(" Nama Toko ")])])],1)],1)],1),_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"button-tambah-toko w-100 mx-1 pbz-font body-sm-bold my-3",attrs:{"htmlType":"submit"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" Simpan ")]),_c('div',{staticClass:"pbz-font body-md-bold button-to-dashboard mb-3",staticStyle:{"color":"#202759"},on:{"click":function($event){return _vm.$emit('handleCancel')}}},[_vm._v(" Batal ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }