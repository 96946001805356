<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
  >
    <path d="M0.0582 21.9418C-0.381189 21.5024 1.77997 15.8261 2.75965 14.8465C3.73933 13.8668 8.13322 18.2607 7.15354 19.2404C6.17386 20.22 0.497589 22.3812 0.0582 21.9418Z" fill="currentColor" />
    <path d="M8.17244 17.7C10.0502 16.5513 18.4721 8.09396 19.0756 6.69062C19.707 5.22222 16.7778 2.29296 15.3094 2.92443C13.906 3.52792 5.44868 11.9498 4.29995 13.8276C5.46795 14.2203 7.77965 16.532 8.17244 17.7Z" fill="currentColor" />
    <path d="M20.0705 6.11066C20.2315 4.38298 17.617 1.76846 15.8893 1.92949C16.9167 1.00392 17.7053 0.353647 18.0673 0.166495C19.8 -0.729262 22.7293 2.2 21.8335 3.93268C21.6464 4.2947 20.9961 5.0833 20.0705 6.11066Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'Pencil',
}
</script>
