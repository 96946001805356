<template>
  <a-modal
    :visible="visible3"
    :footer="null"
    :closable="true"
    class="modal__setupchannel3"
    @cancel="handleCancel"
  >
    <template>
      <Delete
        v-if="modalContent.Delete"
        :channel-clicked="channelClicked"
        @handleCancel="handleCancel"
      />
    </template>
  </a-modal>
</template>

<script>
import Delete from '@/components/Integration/ModalIntegrationPage/Delete/index.vue'

export default {
  components: {
    Delete,
  },
  props: ['visible3', 'channelClicked'],
  data: function () {
    return {
      modalContent: {
        Delete: true,
      },
    }
  },

  mounted () {

  },

  methods: {
    setModalContentDelete() {
      this.modalContent.Delete = true
    },

    handleCancel() {
      this.visible3 = false
    },

  },
}
</script>

<style lang="scss">
.modal__setupchannel3 {
  .ant-modal {
    width: 720px !important;
    &-content {
      width: fit-content;
      padding-top: 1rem !important;
      border-radius: 10px !important;
      background: #ffffff !important;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    }

    .sku {
      position: relative;
      // width: 100px !important;
    }

    .button-set-store {
      width: 174px;
      height: 52px;
      color: #ffffff;
      background: #202759;
      border: 1px solid #202759;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 11px;
    }

    .button-set-store:hover {
      background: #323d8c !important;
      border: 1px solid #323d8c !important;
    }

    .button-to-dashboard:hover {
      color: #323d8c !important;
      text-decoration: underline !important;
      text-decoration-color: #323d8c !important;
      cursor: pointer !important;
    }
  }
}
</style>
